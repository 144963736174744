body {
  font-family: 'CircularStd, Euclid Circular B Regular', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: $text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader-segment {
  color: $primary !important;

  &::before {
    border-color: $lineColor !important;
  }
}

a > img {
  display: inherit;
}

.max-height-80 {
  max-height: 80vh;
}

.max-height-70 {
  max-height: 70vh;
}

.max-height-60 {
  max-height: 60vh;
}

.height-60-vh {
  height: 60vh;
}
.height-70-vh {
  height: 70vh;
}
.height-80-vh {
  height: 80vh;
}
.min-w-40 {
  min-width: 40rem;
}
.min-w-30 {
  min-width: 30rem;
}
.min-w-20 {
  min-width: 20rem;
}
.min-w-10 {
  min-width: 10rem;
}

.min-h-5 {
  min-height: 5rem;
}

.icon {
  color: $icon;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.zoom-1\.3 {
  zoom: 1.3;
}

.icon {
  color: $icon;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: 0;
}

.react-datepicker-popper {
  z-index: 11;
}

.display-from-textarea {
  white-space: pre-wrap;
}
