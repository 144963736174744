$black: #19191e;
$white: #ffffff;
$orange: #e3c597;
$lightGrey: #939aa4;
$grey: grey;
$blue: #0366d6;

$color1: #2c4142;
$color1-60: rgba($color1, 0.6);
$color1-40: rgba($color1, 0.4);
$color1-20: rgba($color1, 0.2);

$color2: #457672;

$primary: $color1;

:export {
  black: $black;
  white: $white;
  orange: $orange;
  lightGrey: $lightGrey;
  blue: $blue;
  color1: $color1;
  color1-60: $color1-60;
  color1-40: $color1-40;
  color1-20: $color1-20;
  color2: $color2;
  primary: $primary;
}
